import { useEffect, useState } from 'react';
import axios from 'axios';

import { Layout, Radio, RadioChangeEvent } from 'antd';
import { Col, Row, Card, Typography, message, Spin, Button, Switch } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Result } from './components/result';
import { Result2 } from './components/result2';
import { Help } from './components/help';
import { ImageInfo } from './components/image-info';
import { useThemeSwitcher } from "react-css-theme-switcher";

import './App.less';

const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography;

const api_url = process.env.REACT_APP_API_URL || '';
const prediction_endpoint = api_url + '/predict';
console.log(prediction_endpoint);

const demo_images = require('./demo_images.json');
const categories = [
  { label: 'OOD (17cl) set', value: 'out_scores' },
  { label: 'Dermoscopy triage correct set', value: 'triage_correct' },
  //{ label: 'Dermoscopy triage incorrect set', value: 'triage_incorrect' },
  { label: 'Dermoscopy triage no change set', value: 'triage_nochange' },
  { label: 'OOD (Unkown) set', value: 'unk_scores' },
];
const category_descriptions = [
  {value: 'out_scores', description: 'An exemplar set of images from the reserved OOD (17cl) categories from our dataset.'},
  {value:'triage_correct', description: 'An exemplar set of images for which HOT skin model recommends a dermoscopy image acquisition (Dermoscopy triage) and if pursued, it results into a correct diagnosis.'},
  //{value:'triage_incorrect', description: 'An exemplar set of images for which HOT skin model does not recommend a dermoscopy image acquistion (Dermoscopy triage) and if nevertheless pursued, it results into the same diagnosis as based on the clinical image only.'},
  {value:'triage_nochange', description: 'An exemplar set of images for which HOT skin model does not recommend a dermoscopy image acquisition (Dermoscopy traige), but nevertheless, if pursued it results into the same diagnosis as based on the clinical image.'},
  {value:'unk_scores', description: 'An exemplar set of images from the commonly encountered images in the clinic (OOD (Unk)).'},
]

const tpr = 'TPR95';

const getLabel = (c: string) => {
  for (var i=0; i < categories.length; i++) {
    if (categories[i].value === c) {
      return categories[i].label;
    }
  }
}

const getDescription = (c: string) => {
  for (var i=0; i < category_descriptions.length; i++) {
    if (category_descriptions[i].value === c) {
      return category_descriptions[i].description;
    }
  }
}

function App() {

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { switcher, themes } = useThemeSwitcher();
  const [isModalHelpOpen, setIsModalHelpOpen] = useState(false);

  const [category, setCategory] = useState<string>('out_scores');
  const [images, setImages] = useState([]);

  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrl2, setImageUrl2] = useState<string>();
  const [working, setWorking] = useState<boolean>(false);
  const [working2, setWorking2] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [data2, setData2] = useState<any>({});
  const [showHierResult, setShowHierResult] = useState<boolean>(false);
  const [resultColWidth, setResultColWidth] = useState<any>(24);


  const toggleTheme = (isChecked: boolean) => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };

  const onShowResultChange = (checked: boolean) => {
    setShowHierResult(checked);
    checked === true ? setResultColWidth(12) : setResultColWidth(24);
  };

  const updateImageList = () => {
    const imgs = (demo_images[category]['mac']).map( (item: string) => 'demo_images/' + category +  '/thumb/mac/' + item );
    setImages(imgs);
  }

  const handleHelpOk = () => {
    setIsModalHelpOpen(false);
  }

  const callPrediction = (img: any, modality: string) => {
    const config= {
      "params": {
        modality: modality,
        category: category,
        macimage: img
      }
    }
    console.log(config);
    axios.post(prediction_endpoint, null, config).then((res: any) => {
      console.log(res.data);
      if(modality === 'mac') {
        setData(res.data);
        setWorking(false);
        // always show corresponding mic image
        setImageUrl2('demo_images/' + category +  '/thumb/mic/' + res.data.mic_image);
      }
      else {
        setData2(res.data);
        setWorking2(false);
      }
    }).catch((err: Error) => {
      console.log(err);
      setWorking(false);
      setWorking2(false);
      message.error(`Failed to upload and get prediction`);
    })
  }

  const handleImageClick = (img: any) => {
    setWorking(true);
    setImageUrl(img);
    setImageUrl2('');
    setData2({});
    // reset show Hier Result
    setShowHierResult(false);
    setResultColWidth(24)
    // Call prediction
    callPrediction(img, 'mac');
  }

  const runTriage = () => {
    setData2({});
    setWorking2(true);
    callPrediction(imageUrl, 'micmac');
  }

  const onCategoryChange = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio1 checked', value);
    setCategory(value);
  };

  // init
  useEffect(() => {
    updateImageList();
  }, [category]);

  return (
    <>
      <Layout style={{minHeight:"100vh"}}>

        <Help isModalHelpOpen={isModalHelpOpen} handleHelpOk={handleHelpOk} />

        <Header>
          <Row>
            <Title style={{color: "#eee", paddingTop: "12px"}} level={2}>Hierarchical-Out of Distribution Detection-Clinical Triage (HOT) Skin Model</Title>
            <div style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={() => setIsModalHelpOpen(true)} icon={<InfoOutlined/>} shape="circle" />
            </div>
          </Row>
        </Header>

        <Content style={{padding: "10px"}}>

          <Row className="row" gutter={16} style={{paddingLeft: "20px", paddingTop: "10px", paddingBottom: "4px"}}>
            <Radio.Group options={categories} onChange={onCategoryChange} value={category} optionType="button" buttonStyle="solid" />
          </Row>

          <Row className="row" gutter={16} style={{paddingLeft: "20px", paddingBottom: "6px"}}>
            <Text>{getDescription(category)}</Text>
          </Row>

          <Row className="row" gutter={16}>

            <Col xs={24} >
              <Card>
                <ScrollMenu>
                  {images.map( item => (
                    <img alt={item} key={item} src={item} className="img-item" onClick={() => handleImageClick(item)} />
                  ))}
                </ScrollMenu>
              </Card>

            </Col>
          </Row>

          { (!imageUrl && !working) &&
            <Row className="row" gutter={16}>
              <Col xs={24} style={{"textAlign": "center"}}>
                <Title level={3} style={{"color": "#aaa"}}>Hierarchical-Out of Distribution Detection-Clinical Triage (HOT) Skin Model demo</Title>
                <Title level={5} style={{"color": "#777"}}>Please choose a clinical image to start!</Title>
              </Col>
            </Row>
          }

          <Row className="row" gutter={16}>
            <Col xs={24} lg={6}>
              <ImageInfo category={getLabel(category)} imageUrl={imageUrl} imageUrl2={imageUrl2} />
            </Col>

            <Col xs={24} lg={18}>
              {working === true ? <Row className="spin-container"><Col xs={24} lg={18} className="spin-container"><Spin size="large" /></Col></Row> :
                <>
                  {Object.keys(data).length !== 0 && <Row>
                      <Title level={5}>The prediction result for the clinical image</Title>
                      {/*
                      <div style={{ marginLeft: "auto" }}>
                        <b>Hierarchical only model results</b> <Switch style={{width: "10px"}} checked={showHierResult} onChange={onShowResultChange}/>
                      </div>
                      */}
                    </Row>
                  }
                  <Row>
                    <Col xs={24} lg={resultColWidth}>
                      <Result type="openset" tpr={tpr} data={data} runTriage={runTriage} working={working2}></Result>
                    </Col>

                    {showHierResult &&  <Col xs={24} lg={resultColWidth}>
                      <Result type="hier" tpr={tpr} data={data}></Result>
                    </Col>}
                  </Row>

                  {Object.keys(data2).length !== 0 &&
                    <>
                      <Row>
                        <div style={{paddingTop: "20px"}}><Title level={5}>The prediction result for the demoscopic image</Title></div>
                      </Row>

                      <Row>
                        <Col xs={24} lg={resultColWidth}>
                          <Result2 type="openset" data={data2}></Result2>
                        </Col>

                        {showHierResult &&  <Col xs={24} lg={resultColWidth}>
                          <Result2 type="hier" data={data2}></Result2>
                        </Col>}
                      </Row>
                    </>
                 }

                </>
              }
            </Col>

          </Row>
        </Content>

        <Footer>
          © 2023 Copyright: Monash University
        </Footer>

      </Layout>
    </>
  )
}

export default App;
