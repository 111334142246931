import { Modal, Table, Image, Typography } from 'antd';
import type { TabsProps } from 'antd';

const { Title } = Typography;


const in_classes = ['benign:keratinocytic:actinic cheilitis', 'benign:keratinocytic:ink spot lentigo', 'benign:keratinocytic:lentigo', 'benign:keratinocytic:lichenoid', 'benign:keratinocytic:porokeratosis', 'benign:keratinocytic:seborrhoeic keratosis', 'benign:keratinocytic:solar lentigo', 'benign:keratinocytic:wart', 'benign:melanocytic:acral', 'benign:melanocytic:atypical', 'benign:melanocytic:benign nevus', 'benign:melanocytic:blue', 'benign:melanocytic:compound', 'benign:melanocytic:congenital', 'benign:melanocytic:dermal', 'benign:melanocytic:halo', 'benign:melanocytic:involutingregressing', 'benign:melanocytic:irritated', 'benign:melanocytic:junctional', 'benign:melanocytic:lentiginous', 'benign:melanocytic:papillomatous', 'benign:melanocytic:spitzoid', 'benign:melanocytic:ungual', 'benign:other:chrondrodermatitis', 'benign:other:comedone', 'benign:other:dermatitis', 'benign:other:dermatofibroma', 'benign:other:eczema', 'benign:other:excoriation', 'benign:other:foliculitis', 'benign:other:nail dystrophy', 'benign:other:scar', 'benign:other:sebaceous hyperplasia', 'benign:vascular:angioma', 'benign:vascular:haematoma', 'benign:vascular:telangiectasia', 'malignant:bcc:basal cell carcinoma', 'malignant:bcc:pigmented basal cell carcinoma', 'malignant:bcc:superficial basal cell carcinoma', 'malignant:keratinocytic:actinic keratosis', 'malignant:melanoma:lentigo maligna', 'malignant:melanoma:melanoma', 'malignant:scc:scc in situ', 'malignant:scc:squamous cell carcinoma'];
const ood_classes = ['benign:melanocytic:agminate', 'benign:melanocytic:en cockarde', 'benign:melanocytic:ephilides', 'benign:melanocytic:melanosis', 'benign:melanocytic:reed nevus', 'benign:other:accessory nipple', 'benign:other:epidermal cyst', 'benign:other:fibrous papule face', 'benign:other:granuloma annulare', 'benign:other:molluscum contagiosum', 'benign:other:myxoid cyst', 'benign:other:psoriasis', 'benign:other:skin tag', 'benign:vascular:angiokeratoma', 'benign:vascular:other', 'malignant:melanoma:nodular melanoma', 'malignant:scc:keratoacanthoma'];


let dataSource:any =  [];
for (var i=0; i < in_classes.length; i++) {
    dataSource.push({key: i, index: i+1, in_class: in_classes[i], ood_class: ood_classes[i] || ''})
}

const columns = [
    {
        title: 'Index',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'IN class',
        dataIndex: 'in_class',
        key: 'in_class',
    },
    {
        title: 'OOD class',
        dataIndex: 'ood_class',
        key: 'ood_class',
    },
]

const items: TabsProps['items'] = [
    {
        key: '1',
        label: `True Positive Rate (TPR) abc`,
        children: <div>
            <div style={{paddingBottom:"15px"}}><Image style={{width:"100%"}} src='./AUROC-curve.jpg' preview={false}></Image></div>
            <p>The TPR here corresponds to all the "IN-classes" samples which will be considered as TP if the confidence prediction of that sample is higher than the threshold operating point.</p>
            <p>The FPR corresponds to all the "OOD" samples which will be considered as FP if the confidence of that sample is higher than the threshold operating point.</p>
            <p>Ideally, we would like to have a higher TPR with lower FPR. Please see the image below for the TPR/FPR trade-off.</p>
        </div>,
    },
    {
        key: '2',
        label: `IN/OOD Classes`,
        children: <div className="hoz-scroll">
            <Table dataSource={dataSource} columns={columns} />
        </div>
    }
];


export function Help(props: any) {

    // <Tabs defaultActiveKey="1" items={items} />

    return (
        <>
            <Modal open={props.isModalHelpOpen} title='' width={800} onOk={props.handleHelpOk} onCancel={props.handleHelpOk} >
                <Title level={3}>IN/OOD Classes</Title>
                <Table dataSource={dataSource} columns={columns} />
            </Modal>
        </>
    );
}