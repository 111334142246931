import { Typography, Card, Button } from 'antd';

const { Title } = Typography;

export function Result(props: any) {

    const isNotEmpty = (obj: any) => {
        return Object.keys(obj).length !== 0;
    }

    const format = (d: any) => {
        const percent = (d.prob*100).toString() + '%';
        const label = d.pred + ' (' + d.prob.toFixed(4).toString() + ')';
        return {percent, label};
    }

    const title = (type: string) => {
       return type === 'hier' ? 'Hierarchical Only Model' : 'HOT Skin Model';
    }

    const result = (d: any) => {
        if (d.lev2_confident_score < d.ood_thresh) {
            return `As the confidence score for level 3 (${d.lev2_confident_score.toFixed(4)}) is less than the threshold (${d.ood_thresh.toFixed(4)}) so the model recommends it's an OOD Image`
        }
        else {
            if (d.min_lev2_dist > d.triage_thresh) {
                return `The model has made an analyis in feature space and found that prediction result may be improved by a corresponding dermoscopic image`
            }
            else {
                return `The model has made an analyis in feature space and found that it may not be necessary to acquire a corresponding dermoscopic image. The diagnosis based on the clinical image is sufficient`
            }
        }
    }

    return (
        <>
            { isNotEmpty(props.data) &&
                <Card hoverable={false} style={{width:"100%", height:"100%"}}>
                    {/*<Title level={2} style={{textAlign: "center"}}>{title(props.type)}</Title>*/}

                    <Title level={5}>Level 1</Title>
                    <div className="bar-grey">
                        <div className="bar-blue" style={{width: format(props.data[props.type]['l0']).percent}}>
                            <span className="bar-text">{format(props.data[props.type]['l0']).label}</span>
                        </div>
                    </div>

                    <Title level={5}>Level 2</Title>
                    <div className="bar-grey">
                        <div className="bar-blue" style={{width: format(props.data[props.type]['l1']).percent}}>
                            <span className="bar-text">{format(props.data[props.type]['l1']).label}</span>
                        </div>
                    </div>

                    <Title level={5}>Level 3</Title>
                    <div className="bar-grey">
                        { (props.type === 'openset' && props.data.lev2_confident_score < props.data.ood_thresh) && <div className="bar-red" style={{left: "25.5%"}}></div> }
                        <div className="bar-blue" style={{width: format(props.data[props.type]['l2']).percent}}>
                            <span className="bar-text">{format(props.data[props.type]['l2']).label}</span>
                        </div>
                    </div>

                    { props.type === 'openset' &&
                        <>
                            { props.data.lev2_confident_score < props.data.ood_thresh ? <p style={{color: 'red'}}><b>{result(props.data)}</b></p> : <p><b>{result(props.data)}</b></p>}

                            { props.data.triage_recommend === true ?
                                <>
                                    <p><b>A Dermoscopic Image Acquisition (Dermoscopy Triage) is recommended to validate more accurately</b></p>
                                    <Button type="primary" onClick={props.runTriage} loading={props.working}>Run clinical triage</Button>
                                </>
                                : <>
                                    <p><b>If you still wish to pursue a dermoscopy image acquistion, please click on 'Run clinical triage' button below</b></p>
                                    <Button type="primary" onClick={props.runTriage} loading={props.working}>Run clinical triage</Button>
                                </>
                            }
                        </>
                    }

                </Card>
            }
        </>
    );
}