import { Card, Space, Image, Typography } from 'antd';
const { Title } = Typography;


const getGT = (fn: string) => {
  let name = fn.split("/").at(-2);
  let parts = name?.split('_');
  if (parts && parts.length > 2)
    return `${parts[0]}:${parts[1]}:${parts[2]}`;
  return 'Unknown';
}

export function ImageInfo(props: any) {

    return (
        <>
            { props.imageUrl &&
                <>
                  <Title level={5}>Selected image</Title>
                  <Card style={{width:"100%"}}>
                    <Space direction="vertical">
                      <div><Title level={5}>Clinical image</Title></div>
                      <Image style={{width:"100%"}}  src={props.imageUrl} />
                      <div style={{wordBreak: "break-all"}}><b>Category:</b> {props.category}</div>
                      <div style={{wordBreak: "break-all"}}><b>Ground truth:</b> {getGT(props.imageUrl)}</div>
                      {props.imageUrl2 &&
                        <>
                          <div style={{paddingTop: "10px"}}><Title level={5}>Corresponding dermoscopic image</Title></div>
                          <Image style={{width:"100%"}}  src={props.imageUrl2} />
                        </>
                      }
                    </Space>
                  </Card>

                </>
            }
        </>
    );
}